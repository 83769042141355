import React from 'react'
import parse from 'html-react-parser'
import ProductList from '../components/Product/ProductList'

const ProductCategoryArchivePage = ({ location, archivePath, pageInfo, pageData, categories, products, isLang }) => {
    const {} = pageData

    return (
        <div className='global-container'>
            <h1 className='global-title-h1'>{parse(pageData.title)}</h1>
            <ProductList products={products} categories={categories} isLang={isLang} />
        </div>
    )
}

export default ProductCategoryArchivePage
