import React from 'react'
import parse from 'html-react-parser'

import ProductList from '../components/Product/ProductList'
import Subscription from "../components/Subscription";

const ProductArchivePage = ({ location, archivePath, pageInfo, pageData, categories, products, isLang,dataSubscription }) => {
    const {} = pageData

    return (
        <div className='global-container'>
            <h1 className='global-title-h1'>{parse(pageData.title)}</h1>
            <ProductList products={products} categories={categories} isLang={isLang} />
            {/*{dataSubscription.subscriptionTitle && (<Subscription data={dataSubscription} isLang={isLang}/>)}*/}

        </div>
    )
}

export default ProductArchivePage
