import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import ProductCategoryArchivePage from '../ProductCategoryArchivePage'
import ProductArchivePage from '../ProductArchivePage'
import Subscription from '../../components/Subscription'

const productArchive = props => {
    const {
        data: {
            filteredPosts: {nodes, pageInfo},
            allWpPage,
            allWpTermNode,
            allWp
        },
        pageContext: {archiveType, archivePath, uri, lang},
        location,
    } = props
    //const pageData = lang ? allWpPage.nodes[1] : allWpPage.nodes[0]
    const pageData = allWpPage.nodes[0]
    const categoryData = allWp.nodes[0].optionPage.themeOptions;
    //let categoriesData = allWpTermNode.nodes;
    const categoriesData = lang ? categoryData.orderCategories : categoryData.orderCategoriesEn;
    let productsData = [];
    if (lang) {
        nodes.map(item => {
            if (item.language.code === 'RU') {
                productsData.push(item);
            }
        })
    } else {
        nodes.map(item => {
            if (item.language.code === 'EN') {
                productsData.push(item);
            }
        })
    }
    const {title} = pageData.seo
    const subscription = pageData.subscription

    let isHideOnDesktop = true;
    console.log('...ARCHIVE Product data start...')
    // console.log(props)
    console.log('...ARCHIVE Product data end...')

    return (
        <Layout bodyClass='archive' isFooterPromo={true} isHideText={true} isHideOnDesktop={isHideOnDesktop}
                isLang={lang} isGoogleMapsInFooterPromo={true}>
            <Seo title={title ? title : pageData.title} meta={pageData.seo} uri={uri}/>

            {archiveType === 'category' ? (
                <ProductCategoryArchivePage
                    location={location}
                    archivePath={archivePath}
                    pageInfo={pageInfo}
                    pageData={pageData}
                    categories={categoriesData}
                    products={productsData}
                    isLang={lang}
                />
            ) : (
                <ProductArchivePage
                    location={location}
                    archivePath={archivePath}
                    pageInfo={pageInfo}
                    pageData={pageData}
                    categories={categoriesData}
                    products={productsData}
                    isLang={lang}
                    dataSubscription={subscription}
                />
            )}
        </Layout>
    )
}

export const query = graphql`
    query archiveProduct($categoryDatabaseId: Int) {
        filteredPosts: allWpProduct(
            filter: { productCategories: { nodes: { elemMatch: { databaseId: { eq: $categoryDatabaseId } } } } }
            sort: {order: ASC, fields: date}
        ) {
            nodes {
                ...ProductPreview
                language {
                    code
                }
            }

            pageInfo {
                hasNextPage
                hasPreviousPage
                currentPage
                pageCount
            }
        }
        allWpPage(filter: {slug: {in: ["menu","menu-2"]}}) {
            nodes {
                id
                title
                uri
                seo {
                    title
                    metaDesc
                    focuskw
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    opengraphSiteName
                    opengraphDescription
                    opengraphImage {
                        link
                    }
                    twitterDescription
                    twitterTitle
                    twitterImage {
                        link
                    }
                    fullHead
                }
                featuredImage {
                    node {
                        altText
                        localFile {
                            ...ProductThumbnail
                        }
                    }
                }
                language {
                    code
                }
                subscription {
                    subscriptionTitle
                    subscriptionContent
                    subscriptionForm {
                        subscriptionFormLabel
                        subscriptionFormButton
                        subscriptionFormPromo
                    }
                }
            }
        }
        allWpTermNode(filter: { taxonomyName: { eq: "products" } }, sort: { fields: databaseId }) {
            nodes {
                ... on WpProductCategory {
                    databaseId
                    id
                    name
                    uri
                    language { 
                        code
                    }
                }
            }
        }
        allWp {
            nodes {
                optionPage {
                    themeOptions { 
                        orderCategories {
                            databaseId
                            id
                            name
                            uri
                            language {
                                code
                            }
                        }
                        orderCategoriesEn {
                            databaseId
                            id
                            name
                            uri
                            language {
                                code
                            }
                        }
                    }
                }
            }
        }
    }
`

export default productArchive
